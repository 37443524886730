.HeroSlider_main {
  /* height: 100vh; */
}
.slider_hero_inner_main {
  /* height: 100vh; */
}
.hero_content_main {
  margin: 0 auto;
  max-width: 1140px;
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
}
.product_heading {
  font-size: 30px;
  color: white;
  max-width: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product_heading_groundnut{
    /* max-width: 403px; */
}

.product_image_main {
  max-width: 500px;
  display: flex;
  max-height: 400px;
  align-items: center;
  justify-content: center;
}

.product_groundnutimage,
.product_cornoilimage{
  overflow: hidden;
}
.product_image {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
 
@media screen and (max-width:1150px) {
  .hero_content_main{
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (max-width: 930px) {
  
  .hero_content_main{
    flex-direction: column-reverse;
    justify-content: center;
  }

  .product_heading{
    font-size: 22px;
    max-width: 100%;
  }
}

@media screen and (max-width: 630px) {
  .hero_content_main {
    /* justify-content: space-evenly; */
  }
/* 
  .hero_content_main{
    flex-direction: column-reverse;
    justify-content: center;
  } */



  .product_heading{
    width: 100%;
    max-width: 100%;
    align-items: center;
    font-size: 24px;
    text-align: center;
  }
}