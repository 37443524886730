.hero-sec {
    width: 100%;
    height: 100%;
}

.hero-imgbox {
    height: 100vh;
    width: 100%;
}

.hero-img {
    width: 100%;
    height: 100%;
    /* object-fit: cover; */
}

.hero-commingsoon {
    max-width: 1140px;
    margin: 40px auto 40px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: aqua; */
}

.comming-head {
    color: red;
    font-weight: 600;
    font-size: 42px;
    margin: 0px;
}

@media screen and (max-width: 1050px) {
    .hero-imgbox {
        height: 80vh;
    }
}

@media screen and (max-width: 650px) {
    .hero-img {
        /* height: 70vh; */
        object-fit: unset;
    }

    .hero-commingsoon {
        margin-top: 20px
    }
}

@media screen and (max-width: 420px) {
    .hero-img {
        height: 65vh;
        object-fit: unset;
    }

    .hero-commingsoon {
        margin-top: 0px
    }

}


@media screen and (min-width: 992px) {
    /* .section {
    width: 95vw;
  } */
}

/*
=============== 
Slider
===============
*/
.title {
    text-align: center;
    margin-bottom: 2rem;
}

.title h2 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
}

.title span {
    font-size: 0.85em;
    color: var(--clr-primary-5);
    margin-right: 1rem;
    font-weight: 700;
}

.section-center-home-hero {
    /* margin: 0 auto; */
    /* margin-top: 4rem; */
    /* margin-top: 110px; */
    width: 100%;
    /* have to have a height */
    height: 85vh;
    /* max-width: 800px; */
    text-align: center;
    position: relative;
    display: flex;
    overflow: hidden;
    /* margin-bottom: 80px; */
}

.person-img-home-hero-slider {
    /* border-radius: 50%; */
    /* margin-bottom: 1rem; */
    width: 100%;
    height: 100%;
    min-height: 85vh;
    min-width: 100vw;
    object-fit: unset;
    /* border: 4px solid var(--clr-grey-8); */
    /* box-shadow: var(--dark-shadow); */
}

article h4 {
    text-transform: uppercase;
    color: var(--clr-primary-5);
    margin-bottom: 0.25rem;
}

.title {
    text-transform: capitalize;
    margin-bottom: 0.75rem;
    color: var(--clr-grey-3);
}

.text {
    max-width: 35em;
    margin: 0 auto;
    margin-top: 2rem;
    line-height: 2;
    color: var(--clr-grey-5);
}

.icon {
    font-size: 3rem;
    margin-top: 1rem;
    color: var(--clr-primary-5);
}

.prev,
.next {
    position: absolute;
    top: 200px;
    transform: translateY(-50%);
    background: var(--clr-grey-5);
    color: var(--clr-white);
    width: 1.25rem;
    height: 1.25rem;
    display: grid;
    place-items: center;
    border-color: transparent;
    font-size: 1rem;
    border-radius: var(--radius);
    cursor: pointer;
    transition: var(--transition);
}

.prev:hover,
.next:hover {
    background: var(--clr-primary-5);
}

.prev {
    left: 0;
}

.next {
    right: 0;
}

@media (min-width: 800px) {
    .text {
        max-width: 45em;
    }

    .prev,
    .next {
        width: 2rem;
        height: 2rem;
        font-size: 1.5rem;
    }
}

article {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: var(--transition);
}

article.activeSlide {
    opacity: 1;
    transform: translateX(0);
}

article.lastSlide {
    transform: translateX(-100%);
}

article.nextSlide {
    transform: translateX(100%);
}

@media screen and (max-width: 778px) {
    .section-center-home-hero {
        height: 44vh;
    }

    .person-img-home-hero-slider {
        min-height: 44vh;
        /* object-fit: contain; */
    }
}

@media screen and (max-width:420px) {


    .different-size-flex {
        flex-direction: column;
    }

    .product-sec-1-heading {
        font-size: 32px
    }

    .section-center-home-hero {
        height: 28vh;
    }

    .person-img-home-hero-slider {
        min-height: 28vh;
        object-fit: fill;
    }
}