.perks_main {
  margin: 0 auto;
  max-width: 1140px;
}

.card_main {
  max-width: 250px;
  border: 3px solid;
  padding: 1rem;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s ease;
}

.card_main:hover {
  transform: scale(1.05);
}

.perks_main_inner {
  display: flex;
  gap: 2rem;
  justify-content: center;
  margin-bottom: 6rem;
  flex-wrap: wrap;
  --hover: 0;
}

.perk-card-head {
  text-align: center;
}

.perk-card-txt {
  text-align: center;
  margin-top: 0px;
  margin-bottom: 0px;
}

.perks_main_inner:hover {
  --hover: 3;
}
.card {
  height: 100px;
  width: 100px;
}
.card_img {
  height: 100%;
  width: 100%;
}
