/* .our-products-sec {
  width: 100%;
  height: 100%;
  margin-bottom: 80px;
  margin-top: 80px;
  padding-bottom: 1rem;
}
.feature-head {
  font-size: 32px;
  font-weight: 600;
  text-align: center;
  color: red;
}

.pro_slider_main {
  max-width: 1140px;
  margin: 0 auto;
  padding-left: 10rem;
}

.product-home-imgbox {
  max-width: 180px;
  max-height: 650px;
}

.product-img {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center !important;
}

@media screen and (max-width: 1500px) {
  .pro_slider_main {
    padding-left: 6rem;
  }
}
@media screen and (max-width: 1200px) {
  .pro_slider_main {
    padding-left: 10rem;
  }
}
@media screen and (max-width: 1100px) {
  .pro_slider_main {
    padding-left: 7rem;
  }
}
@media screen and (max-width: 900px) {
  .pro_slider_main {
    padding-left: 5rem;
  }
}
@media screen and (max-width: 850px) {
  .pro_slider_main {
    padding-left: 9rem;
  }
}
@media screen and (max-width: 700px) {
  .pro_slider_main {
    padding-left: 5.5rem;
  }
}
@media screen and (max-width: 650px) {
  .pro_slider_main {
    padding-left: 10rem;
  }

}
@media screen and (max-width: 550px) {
  .pro_slider_main {
    padding-left: 9rem;
  }
}
@media screen and (max-width: 440px) {
  .pro_slider_main {
    padding-left: 6rem;
  }
} */

/* Carousel.css */

/* Center the entire carousel */
.slick-center {
  transform: scale(1.1);
  /* Zoom effect for the center slide */
  transition: transform 0.3s ease;
}

/* Define the styles for the pagination */
.pagination {
  text-align: center;
  margin: 20px 0;
}

/* Ensure the carousel container does not overflow */
.slick-slider {
  overflow: hidden;
}

/* Customize the slick dots (pagination indicators) */
.slick-dots {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0;
}

.slick-dots li {
  margin: 0 5px;
  padding: 0;
}

.slick-dots li button {
  font-size: 14px;
  line-height: 1;
  color: #333;
  /* Change the color as needed */
  background-color: transparent;
  border: 1px solid #333;
  /* Change the color as needed */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  outline: none;
}

.slick-dots li.slick-active button {
  background-color: #333;
  /* Change the active color as needed */
  border-color: #333;
  /* Change the active color as needed */
  color: #fff;
  /* Change the active color as needed */
}

/* Customize the carousel slides */
.center {
  text-align: center;
  /* padding: 20px; */
  padding-top: 20px;
  padding-bottom: 20px;
}

/* background-color: #fff; */
/* box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); 
}

.img_div {
  /* width: 0px !important; */
/* width: 100%;
  height: 100%;
} */

.img_div {
  /* width: 0px !important; */
  max-width: 180px;
  max-height: 300px;
}

.product-img {
  width: 100%;
  height: 100%;
}

/* @media screen and (max-width: 1150px) {
  .home-our-product-main {
    max-width: 950px;
  }
  .home-our-product-main {
    margin-left: 10rem;
  }
} */

/* @media screen and (max-width: 950px) {
  .home-our-product-main {
 margin-left: 4rem;  }
}

@media screen and (max-width: 575px) {
  .home-our-product-main {
    margin-left: 40px;
  }
} */

/* @media screen and (max-width: 420px) {
  .home-our-product-main {
    margin-left: 115px;
  }
}

@media screen and (max-width: 380px) {
  .home-our-product-main {
    margin-left: 100px;
  }
} */

@media (max-width: 768px) {
  .slick-center {
    transform: scale(1);
  }
}
