.nav-sec {
  width: 100%;
  height: 100%;
  /* background-color: #f7bc13; */
  background-color: #fede00;
  height: 130px;
}

.nav-main-nav {
  width: 100%;
  /* height: 100%; */
  position: absolute;
  top: 0;
  left: 0;
}

.nav-head-img {
  width: 100%;
  height: 100%;
  /* box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px; */
}

.nav-con {
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 140px;
}

.nav-logobox {
  max-width: 125px;
  max-height: 125px;
}

.nav-logo {
  width: 100%;
  height: 100%;
}

.nav-linkks {
  display: flex;
  gap: 25px;
  list-style: none;
  align-items: center;
}

.nav-linkk {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
}

.nav-btn-icon-part {
  /* background-color: yellow; */
  height: 100px;
  /* width: 75px; */
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
}

.nav-btn {
  font-size: 30px;
  color: red;
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  cursor: pointer;
  padding-top: 30px;
}

.mobile-nav-main {
  /* max-width: 550px; */
  /* background-color: yellow; */
  background-color: #fede00;
}

.mobile-nav-links {
  display: flex;
  flex-direction: column;
  gap: 20px;
  list-style: none;
  margin: 0px;
  padding-bottom: 20px;
  padding-left: 20px;
}

.mobile-nav-link {
  color: red;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
}

.Shop_Now_Button_Prods {
  color: aliceblue;
  background-color: red;
  border: 0px;
  height: 35px;
  width: 110px;
  font-size: 16px;
  cursor: pointer;
  text-transform: uppercase;
  font-family: var(--font-base), "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: bold;
}

.navbar {
  overflow: hidden;
  background-color: #333;
}

.navbar a {
  float: left;
  font-size: 16px;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.dropdown {
  float: left;
  overflow: hidden;
}

.dropdown .dropbtn {
  font-size: 16px;
  border: none;
  outline: none;
  /* color: white; */
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

.navbar a:hover,
.dropdown:hover .dropbtn {
  background-color: red;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 150px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 4px 0 4px 0;
  border-radius: 10px;
  /* margin-top: 0.5rem; */
}

.dropdown-content a {
  float: none;
  color: black;
  padding: 7px 12px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a:hover {
  border-radius: 10px;
  color: red;
  font-weight: 400;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}
.dropdown:hover .dropdown-content {
  display: block;
}

.shp-now-open :hover .shp-now-open-inner {
}

@media screen and (max-width: 1215px) {
  .dropdown-content{
    right: 5px;
  }
}

@media screen and (max-width: 1140px) {
  .nav-con {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* @media screen and (max-width: 800px) { */
@media screen and (max-width: 970px) {
  .nav-btn-icon-part {
    display: block;
  }

  .mobile-nav-links {
    padding-left: 2rem;
  }

  .nav-con {
    padding: 0 2rem;
  }
  .nav-linkks {
    display: none;
  }

  .dropdown-content{
    left: 20px;
    width: 150px;
  }
}
