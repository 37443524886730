.InvestorDesk_main {
  margin: 0 auto;
  max-width: 1140px;
  /* background-color: red; */
}
.boxes_main {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  gap: 2.5rem;
  row-gap: 1rem;
  margin-bottom: 60px 0px;
  margin: 60px 0px;
}

.boxes_inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid;
  padding: 0.5rem;
  border-radius: 10px;
  width: 200px;
  justify-content: center;
  height: 180px;
  width: 100%;
  transition: transform 0.3s ease;
}

.boxes_inner:hover {
  border-top: 1px solid;
  transform: scale(1.05);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.linkOpen {
  text-decoration: none;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* background-color: red; */
  width: 100%;
  height: 100%;
}

.datanotavailable {
  display: flex;
  justify-content: center;
  font-size: 30px;
  color: black;
  font-weight: 700;
  margin: 3rem 0;
  padding: 10px;
  text-align: center;
}
.areyaar {
  min-height: 50vh;
}
@media screen and (max-width: 1230px) {
  .boxes_main {
    grid-template-columns: 1fr 1fr;
    gap: 0rem;
    row-gap: 1rem;
  }
  .boxes_inner {
    width: 80%;
  }
  .datanotavailable {
    display: flex;
    justify-content: center;
    font-size: 25px;
    color: black;
    font-weight: 600;
    margin: 1rem 0;
  }
}
