/* @import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&family=Pacifico&family=Reem+Kufi:wght@400;500;600;700&family=Signika:wght@300;400;500;600;700&display=swap"); */
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&family=Dosis:wght@200;300;400;500;600;700;800&family=Jost:wght@100;200;300;400;500;600;700;800;900&family=Nova+Square&family=Orbitron&family=Pacifico&family=Playfair+Display:wght@400;500;600;700;800;900&family=Reem+Kufi:wght@400;500;600;700&family=Signika:wght@300;400;500;600;700&family=Source+Sans+3:wght@400;600;700&display=swap');
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "verdana", "Nova Square", sans-serif; */
  /* font-family: "Nova Square", cursive; */
font-family: 'Playfair Display';

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.slick-initialized .slick-slide {
  display: flex !important;
  justify-content: center !important;
}

.red_button {
  color: white;
  background-color: red;
  border: 0px;
  /* padding: 0.3rem 0.8rem; */
  padding: 1rem;
  text-transform: uppercase;
  /* height: 35px;
  width: 110px; */
    border: 2px solid red;
  border-radius: 3px;
}

.red_button:hover {
  background-color: white;
  color: red;
  border: 2px solid red;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}

.red_heading {
  font-size: 32px;
  font-weight: 600;
  text-align: center;
  color: red;
}

p {
  font-weight: 500;
  line-height: 1.5;
}
