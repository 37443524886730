.res-sec {
  width: 100%;
  height: 100%;
}

.res-con {
  max-width: 1140px;
  margin: 80px auto 80px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 45px;
}

.res-imgbox {
  max-width: 500px;
  max-height: 500px;
}

.recipe-img {
  width: 100%;
  height: 100%;
}

.res-last-imgbox {
  width: 100%;
  height: 100%;
}

.recipe-img-last {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 515px) {
  .res-con {
    padding-left: 15px;
    padding-right: 15px;
  }
}



@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInRight {
  animation-name: fadeInRight;
}
 


@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}