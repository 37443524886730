.sec_available_main {
  /* margin: -2rem 0 0rem 0; */
}

.available_main {
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  /* align-items: center; */
  justify-content: space-around;
}

.company_img_main {
  width: 180px !important;
}

.company-img {
  filter: grayscale(100%) invert(2%) contrast(83%);
  width: 100%;
}
.company-img:hover {
  filter: grayscale(0%);
}

.slick-track {
  display: flex;
  align-items: center;
}

.availableon_red_heading {
  font-size: 32px;
  font-weight: 600;
  text-align: center;
  color: red;
}

@media screen and (max-width: 1200px) {
  .sec_available_main {
    /* margin: -2rem 0 0rem 6rem; */
  }
}

@media screen and (max-width: 650px) {
  .sec_available_main {
    /* margin: -2rem 0 0rem 13rem; */
  }

  .availableon_red_heading {
    text-align: center;
  }
}

@media screen and (max-width: 500px) {
  .sec_available_main {
    /* margin: -2rem 0 0rem 8rem; */
  }
}

@media screen and (max-width: 400px) {
  .sec_available_main {
    /* margin: 2rem 0 0rem 6rem; */
  }

  .sec_available_main {
    margin-top: 0px;
  }

  .availableon_red_heading {
    margin-top: 0px;
  }
}

.available_main_img {
  /* max-width: 115px; */
  height: 75px;
}

.avail-img {
  width: 100%;
  height: 100%;
  filter: grayscale(100%) invert(2%) contrast(83%);
}

.avail-img:hover {
  filter: grayscale(0%);
}

.avail-img2 {
  height: 100px;
  transform: translateY(-28px);
}
.avail-img3 {
  height: 110px;
  transform: translateY(-36px);
}

@media screen and (max-width: 1140px) {
  .available_main {
    margin-left: 20px;
    margin-right: 20px;
  }
}
@media screen and (max-width: 780px) {
  .available_main {
    /* padding-top: 2rem; */
    flex-direction: column;
    gap: 3.5rem;
    align-items: center;
  }
  .avail-img2 {
    height: 120px;
    transform: translateY(0px);
  }

  .avail-img3 {
    transform: translateY(0px);
  }
}
@media screen and (max-width: 420px) {
  .available_main_img {
    /* width: 150px;
    height: 120px; */
  }

  .available_main {
    /* flex-direction: column; */
  }
}
