.img_palmoean_all_pro {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img_palmoean_all_pro_main {
  height: 200px;
  width: 430px;
}

.palmolien_singleproduct_bgimage {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

@media screen and (max-width: 1015px) {
  .pro_det_palmolean {
    max-width: 350px;
  }
}
@media screen and (max-width: 870px) {
  .palmolien_singleproduct_bgimage {
    background-size: cover !important;
  }
  .pro_det_palmolean {
    max-width: 600px;
  }
}
@media screen and (max-width: 750px) {
  .palmolien_singleproduct_bgimage {
    padding-top: 4rem;
    padding-bottom: 0rem;
    background-size: cover;
  }

  /* .icons_main{
    padding-bottom: 0rem;
  } */
}
@media screen and (max-width: 450px) {
  .img_palmoean_all_pro_main{
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .img_palmoean_all_pro{
    height: 90%;
    width: 90%;
  }
}
@media screen and (max-width: 400px) {
  .img_palmoean_all_pro_main {
    height: 100%;
    width: 100%;
  }
}
