.pro_img_corn_main{
  display: flex;
  justify-content: center;
  /* width:460px; */
}

@media screen and (max-width: 900px) {
  .cornsinglepro_res {
    background-size: cover !important;
    height: 100% !important;
  }
}
